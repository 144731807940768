<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách lịch sử nhiệm vụ</h6>
              </template>
              <div class="filter-transaction">
                <div class="row mb-6">
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Số điện thoại:</label>
                    <input
                      type="text"
                      class="form-control datatable-input"
                      v-model="paramFilter.phone"
                    />
                  </div>

                  <!--                  <div class="col-lg-2 mb-lg-0 mb-6">-->
                  <!--                    <label>Trạng thái:</label>-->
                  <!--                    <select-->
                  <!--                        class="form-control datatable-input"-->
                  <!--                        data-col-index="7"-->
                  <!--                        v-model="paramFilter.status"-->
                  <!--                    >-->
                  <!--                      <option value="">Tắt cả</option>-->
                  <!--                      <option value="success">PENDING</option>-->
                  <!--                      <option value="fail">SUCCESS</option>-->
                  <!--                      <option value="pending">FAIL</option>-->
                  <!--                    </select>-->
                  <!--                  </div>-->

                  <div class="col-lg-4 mb-lg-0 mb-6">
                    <label>Thời gian hoạt đông:</label>
                    <div class="input-daterange input-group" id="kt_datepicker">
                      <input
                        type="date"
                        v-model="paramFilter.date_from"
                        class="form-control datatable-input"
                        name="start"
                        placeholder="Từ"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                      </div>
                      <input
                        type="date"
                        v-model="paramFilter.date_to"
                        class="form-control datatable-input"
                        name="end"
                        placeholder="Đến"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2 mb-lg-0 mb-6">
                    <label>.</label><br />
                    <button
                      class="btn btn-primary btn-primary--icon"
                      @click="loadData()"
                    >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <table
                data-v-c2d1ace8=""
                role="table"
                aria-busy="false"
                aria-colcount="7"
                class="table b-table datatable-table table-hover"
                id="__BVID__182"
              >
                <!----><!---->
                <thead role="rowgroup" class="datatable-head">
                  <!---->
                  <tr role="row" class="datatable-row">
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      class="datatable-cell"
                    >
                      <div>Max số bước hôm nay</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      class="datatable-cell"
                    >
                      <div>Tổng quà tạo ra / ngày</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="3"
                      class="datatable-cell"
                    >
                      <div>Tổng quà thực nhận / ngày</div>
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <!---->
                  <tr role="row" class="datatable-row">
                    <td aria-colindex="1" role="cell" class="datatable-cell">
                      <span> {{ userBest.max_step }} bước </span> <br />
                      <span> SĐT: {{ userBest.runner.phone }} </span><br />
                      <span> User ID: {{ userBest.uuid }} </span><br />
                      <span v-if="userBest.runner.name">
                        Họ tên: {{ userBest.runner.name }}
                      </span>
                    </td>
                    <td aria-colindex="2" role="cell" class="datatable-cell">
                      <span> {{ reportTotalBoxes }} hộp quà </span> <br />
                      <span> {{ reportTotalBoxes * 5 }}đ </span><br />
                    </td>
                    <td aria-colindex="3" role="cell" class="datatable-cell">
                      <span> {{ reportTotalReceiveBoxes }} hộp quà </span>
                      <br />
                      <span> {{ reportTotalReceiveBoxes * 5 }}đ </span><br />
                    </td>
                  </tr>

                  <!----><!---->
                </tbody>
                <!---->
              </table>

              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(user_info)="data">
                  <span> SĐT : {{ data.item.runner.phone }} </span> <br />
                  <span> User ID: {{ data.item.uuid }} </span><br />
                  <span v-if="data.item.runner.name">
                    họ tên: {{ data.item.runner.name }}
                  </span>
                </template>
                <template #cell(level_info)="data">
                  <span>
                    {{ data.item.runner.level.name }}
                  </span>
                  <br />
                  <span>
                    {{ data.item.runner.level.max_step_in_day }}
                    bước/ngày </span
                  ><br />
                  <span>
                    {{ data.item.runner.level.group_max_get_boxes }} hộp/nhóm
                  </span>
                </template>
                <template #cell(activity_info)="data">
                  <span> {{ data.item.step }} bước </span>
                </template>
                <template #cell(total_box_info)="data">
                  <span> Cá nhân: {{ data.item.boxes }} hộp </span><br />
                  <span>
                    Group:
                    {{ data.item.group_boxes ? data.item.group_boxes : 0 }}
                    hộp </span
                  ><br />
                  <span>
                    Tổng tiền:
                    {{
                      (data.item.boxes +
                        (data.item.group_boxes ? data.item.group_boxes : 0)) *
                      data.item.box_value
                    }}đ
                  </span>
                </template>
                <template #cell(total_box_receives_info)="data">
                  <span> Cá nhân: {{ data.item.received_boxes }} hộp </span
                  ><br />
                  <span>
                    Group:
                    {{
                      data.item.received_group_boxes
                        ? data.item.received_group_boxes
                        : 0
                    }}
                    hộp </span
                  ><br />
                  <span>
                    Tổng tiền:
                    {{
                      ((data.item.received_boxes
                        ? data.item.received_boxes
                        : 0) +
                        (data.item.received_group_boxes
                          ? data.item.received_group_boxes
                          : 0)) *
                      data.item.box_value
                    }}đ
                  </span>
                </template>
                <template #cell(action)="data">
                  <a
                    style="color: #0093ca"
                    href="javascript:void(0)"
                    @click="loadBonusHistories(data.item)"
                    v-b-modal.my-modal
                    >Xem lịch sử nhận thưởng</a
                  >
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>

          <b-modal id="my-modal" hide-footer ref="myModal" v-show="logItems.length > 0">
            <b-table
              table-class="datatable-table"
              hover
              tbody-tr-class="datatable-row"
              thead-tr-class="datatable-row"
              thead-class="datatable-head"
              details-td-class="datatable-cell"
              :items="logItems"
              :fields="fieldsLog"
            >
              <template #cell(type)="data">
                <span v-if="data.item.type == 1"> Cá nhân </span>
                <span v-else> Nhóm </span>
              </template>
              <template #cell(status)="data">
                <span
                  class="
                    label
                    font-weight-bold
                    label-lg label-inline label-light-success
                  "
                  v-if="data.item.status == 2"
                >
                  Success
                </span>
                <span
                  class="
                    label
                    font-weight-bold
                    label-lg label-inline label-light-warning
                  "
                  v-else-if="data.item.status == 1"
                >
                  Pending
                </span>
                <span
                  class="
                    label
                    font-weight-bold
                    label-lg label-inline label-light-danger
                  "
                  v-else
                >
                  ERROR
                </span>
              </template>
              <template #cell(created_at)="data">
                <span>
                  {{ formatTimeLogUnix(data.item.created_at) }}
                </span>
              </template>
            </b-table>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        phone: "",
        status: "",
        date_from: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        date_to: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        limit: 20,
      },
      fields: [
        // {
        //   key: "id",
        //   label: "ID",
        // },
        {
          key: "user_info",
          label: "Thông tin users",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "level_info",
          label: "Cấp độ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "activity_info",
          label: "Hoạt động trong ngày",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_box_info",
          label: "Số quà tạo ra",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_box_receives_info",
          label: "Số quà đã nhận",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_activity",
          label: "Thời gian",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      fieldsLog: [
        // {
        //   key: "id",
        //   label: "ID",
        // },
        {
          key: "boxes",
          label: "Số hộp quà nhận",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "amount",
          label: "Giá trị hộp quà",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_amount",
          label: "Tổng",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "type",
          label: "Loại",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "Ngày",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      logItems: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      userBest: {
        max_step: 0,
        runner: {
          phone: "",
          name: "",
        },
      },
      reportTotalBoxes: 0,
      reportTotalReceiveBoxes: 0,
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Lịch sử đi bộ", route: "/wallet-run/report/activities" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        let response = await WalletRunRepository.getActivityHistories(
          this.paramFilter
        );
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        let dataList = body.list;
        if (body.user_the_best) {
          this.userBest = body.user_the_best;
        } else {
          this.userBest = {
            max_step: 0,
            runner: {
              phone: "",
              name: "",
            },
          };
        }

        this.reportTotalBoxes = body.report_total_boxes;
        this.reportTotalReceiveBoxes = body.report_total_receive_boxes;
        this.items = dataList.data;
        this.currentPage = dataList.current_page;
        this.paginate.total = dataList.total;
        this.paginate.totalPage = dataList.last_page;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async loadBonusHistories(item) {
      try {
        this.$bus.$emit("show-loading", true);
        let params = {
          user_id: item.uuid,
          date: item.date_activity,
        };
        let response = await WalletRunRepository.getBonusHistories(params);
        this.$bus.$emit("show-loading", false);
        this.logItems = response.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
};
</script>

<style scoped></style>
